.base-layout {
  width: 100%;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  // height: 100vh;
  //   background-size: 35%;
  //   background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  will-change: opacity;
}
